import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import CookieConsent from 'react-cookie-consent';

import Header from './containers/Header/Header';
import Home from './components/Home/Home';
import Content from './containers/Content/Content';
import ContentPublished from './containers/ContentPublished/ContentPublished';
import ContentExamples from './containers/ContentExamples/ContentExamples';
import Published from './containers/Published/Published';
import Terms from './static/Terms/Terms';
import Privacy from './static/Privacy/Privacy';
import PasswordReset from './containers/Auth/PasswordReset';
import Navbar from './containers/Navbar/Navbar';
import Modal from './containers/Modal/Modal';
import Footer from './containers/Footer/Footer';

import * as actionTypes from './store/actions/actionTypes';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
/**
 * @description Main Container
 * @summary Header, Navbar, Passwords, Content(s) , Modal and Footer components are loaded here
 * @class App
 * @extends {Component}
 */
class App extends Component {
  
  render() {
    return (
      <React.Fragment>       
        <Switch>
          <Route exact path="/:d/:m/:y" component={Header} />
          <Route exact path="/:date" component={Header} />
          <Route path="/" component={Header} />
        </Switch>
        <Switch>
          <Route path="/api/password/reset/:token" render={(props) => <Navbar {...props} active={null} logged={this.props.isAuth} />} />
          <Route path="/api/password/reset" render={(props) => <Navbar {...props} active={null} logged={this.props.isAuth} />} />
          <Route path="/published" render={(props) => <Navbar {...props} active="/published" logged={this.props.isAuth} />} />
          <Route path="/examples" render={(props) => <Navbar {...props} active="/examples" logged={this.props.isAuth} />} />
          <Route path="/terms" render={(props) => <Navbar {...props} active="/terms" logged={this.props.isAuth} />} />
          <Route path="/privacy" render={(props) => <Navbar {...props} active="/privacy" logged={this.props.isAuth} />} />
          <Route path="/" render={(props) => <Navbar {...props} active="/" logged={this.props.isAuth} />} />
        </Switch>
        <Switch>
          <Route path="/api/password/reset/:token" component={PasswordReset} />
          <Route path="/api/password/reset" component={PasswordReset} />
          <Route path="/terms" component={Terms} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/published/:hash" component={Published} />
          <Route path="/published" component={ContentPublished} />
          <Route path="/examples" component={ContentExamples} />
          <Route path="/" render={(props) => (this.props.isAuth === null)? <Home {...props} key="1" />:<Content {...props} key="1" />} />
        </Switch>
        <CookieConsent
          style={{ textAlign: 'center',  background: 'rgba(53, 53, 53, 0.9)', borderTop: '1px solid #FFF'}}
          buttonStyle={{ backgroundColor: '#FFF', color:'#000', fontSize: '13px' }}
        >
          By using our site, you acknowledge that you have read and understand our <Link style={{ color: 'rgba(0, 106, 141, 1)', fontWeight: 'bold' }} to="/privacy">Privacy Policy</Link> and <Link style={{ color: 'rgba(0, 106, 141, 1)' , fontWeight: 'bold'}} to="/terms">Terms of Service</Link>
        </CookieConsent>
        <Modal show={
          (this.props.shmdl && !this.props.isLM) //if create resolution modal open
          || ((this.props.isAuth === null) && this.props.isLM && this.props.shmdl)
        } hideModal={this.props.onModalClosed} />
      <Footer />
      </React.Fragment>
    );
  }
}

// REDUX
const mapStateToProps = state => {
  return {
    shmdl: state.rdcr.showModal,
    isLM: state.rdcr.isLoginModal,
    isAuth: state.ardcr.token,
    emailR: state.ardcr.email
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onModalClosed: () => dispatch({ type: actionTypes.CLOSE_MODAL })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
