import React, { Component } from 'react';
import classes from './Terms.module.css';
/**
 * @description Terms wrapper component
 * @class Terms
 * @extends {Component}
 */
class Terms extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    
    render() {
        return (
            < main className={classes.Main} >
                <div className={classes.Wrap}>
                    <h2>Terms of Service ("Terms")</h2>
                    <p>Last updated: Jan 15, 2020</p>

                    <p>Please read these Terms of Service ("Terms", "Terms of Service") carefully before using the https://www.resolutions.page (Resolutions page) website.</p>

                    <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.</p>

                    <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</p>

                    <h4>Termination</h4>

                    <p>We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>

                    <p>All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>

                    <h4>Content</h4>

                    <p>Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material ("Content"). </p>
                    <p>You are solely responsible for the User Content that you post or transmit using the Resolutions page and you agree not to post, transmit or otherwise publish through the Resolutions page any of the following:</p>
                    <ul>
                        <li>User Content that is unlawful, defamatory, hateful, harassing, threatening, invasive of privacy or publicity rights, abusive, inflammatory, fraudulent or otherwise objectionable or harmful;</li>
                        <li>User Content that is obscene, pornographic, indecent, lewd, sexually suggestive, including without limitation photos, videos or other User Content containing nudity;</li>
                        <li>User Content that would constitute, encourage or provide instructions for a criminal offense, violate the rights of any party, endanger national security, or that would otherwise create liability or violate any local, state, national or international law;</li>
                        <li>User Content that may infringe or violate any patent, trademark, trade secret, copyright or other intellectual or other proprietary right of any party; User Content that impersonates any person or entity or otherwise misrepresents your affiliation with a person or entity;</li>
                        <li>unsolicited messages containing promotions, political campaigning, advertising or solicitations;</li>
                        <li>private information of any third party, including, without limitation: addresses, phone numbers, email addresses, social security numbers and credit card numbers;</li>
                        <li>viruses, corrupted data or other harmful, disruptive or destructive files; and</li>
                        <li>User Content that is objectionable, harmful or which restricts or inhibits any other person from using or enjoying the Resolutions page, or which may expose Resolutions page or its users to any harm or liability of any nature, or which is legally required to be deleted or made private.</li>
                    </ul>


                    <h4>Links To Other Web Sites</h4>

                    <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by Resolutions page.</p>

                    <p>Resolutions page has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that Resolutions page shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>

                    <h4>Changes</h4>

                    <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>

                    <h4>Contact Us</h4>

                    <p>If you have any questions about these Terms, please contact us.</p>
                </div>
            </main>
        );
    }
}

export default Terms;