import React from 'react';
import classes from './Backdrop.module.css';
/**
 * @description Backdrop component
 * @param {object} props 
 */
const backdrop = (props) => (
    props.show ? <div className={classes.Backdrop} onClick={props.clicked}></div> : null
);

export default backdrop;