import React, {Component} from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader';
import Pagination from '../../components/Pagination/Pagination';

import * as actions from '../../store/actions/actions';

import ResolutionItem from '../../components/UI/ResolutionItem/ResolutionItem';
import classes from '../Content/Content.module.css';
import * as actionTypes from '../../store/actions/actionTypes';

class ContentPublished extends Component {

    constructor(props) {
        super(props)
        this.props.onDataGetAllPublished(this.props.ppg, 1);
    }

    componentDidMount() {
        window.scrollTo(0, 0) 
    }

    /**
     * @description call ajax if/when date changes
     * @param {*} newProps
     * @param {*} newState
     * @returns
     * @memberof Content
     */
    shouldComponentUpdate(newProps, newState) {
        if (newProps.tmsht !== this.props.tmsht) {
            return true;
        }
        return false;
    }

    onChangePage(currentPage) {
        if (parseInt(currentPage) < 1 || parseInt(currentPage) > parseInt(this.props.lp)) return
        //update new current page
        this.props.onPageChange(currentPage);
        // update state with new page of items
        this.props.onDataGetAllPublished(this.props.ppg, currentPage);
    }

    render() {
        let items = null;
        if (this.props.tmsht.length > 0) {
            let tmmshts = this.props.tmsht;
            items = (
                tmmshts.map((elem, k) => {
                    return <ResolutionItem detailshash={elem.hash} startdate={elem.start} reminder={elem.reminder} status={elem.status} untildate={elem.until} key={Math.floor(Math.random() * 100000)} id={elem.id} title={elem.title} body={elem.body} clicked={() => this.props.onModalLoginOpen()}/>
                })
            );
        }

        let final_content = (< main className={classes.Main} >
            <div className="wrap">
            <div style={{ minHeight: "200px"}}>
                    <Loader
                        loaded={null}
                        lines={13}
                        length={20}
                        width={10}
                        radius={30}
                        corners={1}
                        rotate={0}
                        direction={1}
                        color="#000"
                        speed={1}
                        trail={60}
                        shadow={false}
                        hwaccel={false}
                        className="spinner"
                        zIndex={2e9}
                        top="50%"
                        left="50%"
                        scale={1.0}
                        loadedClassName="loadedContent"
                    />
                </div>
            </div>
        </ main>);
        if (this.props.tmsht.length > 0) {
            final_content = (< main className={classes.Main} >
                <div className="wrap">
                    {items}
                </div>
                <div className="wrap">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="text-center">
                                <Pagination
                                    paginationButtons={this.props.pbuttons}
                                    currentPage={this.props.cp}
                                    totalPages={this.props.ttlCnt}
                                    lastPage={this.props.lp}
                                    setPage={this.onChangePage.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </main >);
        }
        return (final_content);
    }
}

// REDUX
const mapStateToProps = state => {
    return {
        tmsht: state.rdcr.timesheet,//items from specific date
        ttlCnt: state.rdcr.totalItemsCount,//from api
        ppg: state.rdcr.perPage,//items per page
        cp: state.rdcr.currPage,//current page
        lp: state.rdcr.lastPage,//last page
        pbuttons: state.rdcr.paginationButtons
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onPageChange: (currentPage) => dispatch({ type: actionTypes.PAGE_CHANGE, current: currentPage }),
        onModalLoginOpen: () => dispatch({ type: actionTypes.OPEN_LOGIN_MODAL, modalTitle: "Authentication", selectedItem: null }),
        onDataGetAllPublished: (perPage, currPage) => dispatch(actions.onDataGetAllPublished(perPage, currPage))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ContentPublished);