import React, { useCallback, useMemo, useState } from 'react';
import { Editable, withReact, Slate } from 'slate-react';
import { Editor, createEditor } from 'slate';

const LIST_TYPES = ['numbered-list', 'bulleted-list']

function isJson(item) {
  item = typeof item !== "string"
    ? JSON.stringify(item)
    : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  if (typeof item === "object" && item !== null) {
    return true;
  }

  return false;
}

const ReadOnly = (props) => {
  let editorContent = null;
  if (isJson(props.body)) {
    editorContent = JSON.parse(props.body);
  } else {
    editorContent = [{
      type: 'paragraph',
      children: [{ text: '' }],
    }];
  }

  const [value, setValue] = useState(
    editorContent
  )
  const renderElement = useCallback(props => <Element {...props} />, []);

  const renderLeaf = useCallback(props => <Leaf {...props} />, [])

  const editor = useMemo(
    () => withRichText(withReact(createEditor())),
    []
  )

  return (
    <Slate
      editor={editor}
      value={value}
      onChange={
        value => {
          setValue(value);
        }
      }>
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder="Steps to resolve your resolution…"
        readOnly
      />
    </Slate>
  )
}

const withRichText = editor => {
  const { exec } = editor

  editor.exec = command => {
    if (command.type === 'format_block') {
      const { format } = command
      const isActive = isBlockActive(editor, format)
      const isList = LIST_TYPES.includes(format)

      for (const f of LIST_TYPES) {
        Editor.unwrapNodes(editor, { match: n => n.type === f, split: true })
      }

      Editor.setNodes(editor, {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
      })

      if (!isActive && isList) {
        Editor.wrapNodes(editor, { type: format, children: [] })
      }
    } else {
      exec(command)
    }
  }

  return editor
}

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === format,
    mode: 'all',
  })

  return !!match
}

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    default:
      return <p {...attributes}>{children}</p>
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

export default ReadOnly;