import React, { Component } from 'react';
import classes from './Quote.module.css';
import quotes from './quoteData.json';
/**
 * @description Display quotes component
 * @summary     JSON quotes are read from local file and randomly displayed on page load
 * @class Quote
 * @extends {Component}
 */
class Quote extends Component {
    /**
     * Getting JSON
     *
     * @memberof Quote
     */
    getQuote = () => {
        return quotes;
    }
    // only load quotes once
    shouldComponentUpdate ( ) {
        return false;
    }

    render() {
        let rnd = Math.floor(Math.random() * (100/1.4));
        return (
            <div className={classes.HeaderBlockquote}>
                <h1 className={classes.HeaderQuote}>{this.getQuote()['quotes'][rnd]['quote']}</h1>
                <div className={classes.HeaderCite}>- {this.getQuote()['quotes'][rnd]['author']}</div>
            </div>
        );
    }
}

export default Quote;