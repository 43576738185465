import axios from 'axios';

import * as actionTypes from './actionTypes';

/* To Reducer - Per Action */
export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token, userId, email) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        userId: userId,
        email: email
    };
};
export const resetSuccess = (response) => {
    return {
        type: actionTypes.RESET_SUCCESS,
        data: response.data
    };
};
export const resetInitSuccess = (response) => {
    return {
        type: actionTypes.RESET_INIT_SUCCESS,
        data: response.data
    };
};


export const authResetInitFail = (error) => {
    return {
        type: actionTypes.AUTH_RESET_INIT_FAIL,
        error: error
    };
};
export const resetFail = (error) => {
    return {
        type: actionTypes.RESET_FAIL,
        error: error
    };
};
export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};
/**
 * @description Handling Login / SignUp process
 * @summary Send request data for Login/SignUp on server
 *
 * @param {*} email
 * @param {*} password
 * @param {*} isSignup
 * @param {*} name
 * @param {*} password_confirmation
 * @returns
 */
export const auth = (email, password, isSignup, name, password_confirmation) => {
    const headers = {
        "Accept": "application/json",
        "Content-Type": "application/json"
    }
    return dispatch => {
        dispatch(authStart());
        const authData = {
            email: email,
            password: password
        };
        let url = 'https://api.resolutions.page/api/login';//login
        if (isSignup) {
            authData.name = name;
            authData.password_confirmation = password_confirmation;
            url = 'https://api.resolutions.page/api/register';//signup
        }
        axios.post(url, authData, headers)
            .then(response => {
                dispatch(authSuccess(response.data.api_token, response.data.name, response.data.email));
            })
            .catch(err => {
                console.log(err);
                dispatch(authFail(err.response));
            });
    };
};

/**
 * @description Update password
 * @summary After user click on link from email this function is triggered and password is updated on backend
 *
 * @param {*} email
 * @param {*} token
 * @param {*} password
 * @param {*} password_confirmation
 * @returns
 */
export const reset = (email, token, password, password_confirmation) => {
    const headers = {
        "Accept": "application/json",
        "Content-Type": "application/json"
    }
    return dispatch => {
        dispatch(authStart());
        const authData = {
            email: email,
            token: token,
            password: password,
            password_confirmation: password_confirmation,
        };
        const url = 'https://api.resolutions.page/api/password/reset';//reset
        axios.post(url, authData, headers)
            .then(response => {
                dispatch(resetSuccess(response));
            })
            .catch(err => {
                console.log(err);
                dispatch(resetFail(err.response));
            });
    };
};

/**
 * @description Initialize reset password process
 * @summary Send request data for password reset by email
 *
 * @param {*} email
 * @returns
 */
export const resetInit = (email) => {
    const headers = {
        "Accept": "application/json",
        "Content-Type": "application/json"
    }
    return dispatch => {
        dispatch(authStart());
        const authData = {
            email: email
        };
        const url = 'https://api.resolutions.page/api/password/email';//reset
        axios.post(url, authData, headers)
            .then(response => {
                dispatch(resetInitSuccess(response));
            })
            .catch(err => {
                console.log(err);
                dispatch(authResetInitFail(err.response));
            });
    };
};