import React, { Component } from 'react';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {isMobile} from 'react-device-detect';

import Input from '../Form/Input/Input';
import Button from './Button/Button';
import { connect } from 'react-redux';
import SlateEditor from '../../SlateEditor/SlateEditor';

import * as actionTypes from '../../../store/actions/actionTypes';
import * as actions from '../../../store/actions/actions';

import classes from './Form.module.css';
import 'typeface-roboto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const options = [
    { value: 0, label: 'Notes For Effective Goals' },
    { value: 1, label: 'Take Charge Of Your Goals' },
    { value: 2, label: 'Habits And Behaviors' },
    { value: 3, label: 'Mindset' },
    { value: 4, label: 'Overcoming Barriers' },
    { value: 5, label: 'Clear templates' },
];

const BlueRadio = withStyles({
    root: {
        '&$checked': {
            color: blue[500],
        },
    },
    checked: {},
})(props => <Radio color="default" {...props} />);

const BlueCheckbox = withStyles({
    root: {
        '&$checked': {
            color: blue[500],
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);

/* const PartialFormControl = withStyles({
    root: {
        width: `calc(50% - 2px)`,
        marginRight: 2,
        display: `inline-grid`
    },
})(props => <FormControl {...props} />); */

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        width: '100%',
        color: state.selectProps.menuColor,
        padding: 20
    }),

    control: (_, { selectProps: { width } }) => ({
        borderRadius: 0,
        borderColor: '#999',
        color: '#999',
        alignItems: 'center',
        backgroundColor: 'hsl(0,0%,100%)',
        borderStyle: 'solid',
        borderWidth: '1px',
        cursor: 'pointer',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        minHeight: '38px',
        outline: '0 !important',
        position: 'relative',
        transition: 'all 100ms',
        boxSizing: 'border-box'
    }),

    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        return { ...provided, opacity, transition };
    }
}

/**
 * @description Form component
 * @summary Wrapper for buttons and inputs :: Initiate CRUD REDUX calls
 *
 * @class Form
 * @extends {Component}
 */
class Form extends Component {

    /**
     * @description Saving form data
     * @summary Prepare data for REDUX ACTION processing
     * @memberof Form
     */
    constructor(props) {
        super(props);
        //this.handleDayClick = this.handleDayClick.bind(this);
        this.state = {
            selectedOption: null,
            isClicked: {
                start: false,
                until: false
            },
            selectedDay: {
                start: null,
                until: null
            }
        }
    }

    saveData = () => {
        let bdy = this.props.tmf.body.value;
        let tl = this.props.tmf.title.value;
        let rmnd = this.props.tmf.reminder.value;
        let stts = this.props.tmf.status.value;
        let pbs = this.props.tmf.published.value;
        let untl = this.props.tmf.until.value.replace(/\//g, '-').replace(/\s/g, '');
        let strt = this.props.tmf.start.value.replace(/\//g, '-').replace(/\s/g, '');
        let dateF = this.props.dtp;//.split(' / ');
        
        let sendData = {
            api_token: this.props.api_token,
            content: bdy,
            title: tl,
            reminder: rmnd,
            start: strt,
            until: untl,
            status: stts,
            published: pbs,
            ui_created_at: dateF
        };
        this.props.onFormSave(sendData);
    }
    /**
     * @description Updating form data
     * @summary Prepare data for REDUX ACTION processing
     * @memberof Form
     */
    updateData = () => {
        let id = this.props.selit;
        let bdy = this.props.tmf.body.value;
        let tl = this.props.tmf.title.value;
        let rmnd = this.props.tmf.reminder.value;
        let stts = this.props.tmf.status.value;
        let pbs = this.props.tmf.published.value;
        let untl = this.props.tmf.until.value.replace(/\//g, '-').replace(/\s/g, '');
        let strt = this.props.tmf.start.value.replace(/\//g, '-').replace(/\s/g, '');
        let dateF = this.props.dtp;//.split(' / ');
        let sendData = {
            api_token: this.props.api_token,
            id: id,
            content: bdy,
            title: tl,
            reminder: rmnd,
            start: strt,
            until: untl,
            status: stts,
            published: pbs,
            _method: 'POST',
            ui_created_at: dateF
        };
        this.props.onFormUpdate(sendData);
    }
    /**
     * @description Deleting form data
     * @summary Prepare data for REDUX ACTION processing
     * @memberof Form
     */
    deleteData = () => {
        let api_token = this.props.api_token;
        let id = this.props.selit;
        this.props.onFormDelete(id, api_token);
    }

    isClicked(id) {

        let tmp = {};
        let tmp2 = {
            ...this.state.selectedDay,
            until: this.state.selectedDay['until'],
            start: this.state.selectedDay['start'],
        }

        if (id === 'until') {
            tmp = {
                ...this.state.isClicked,
                until: true,
                start: false
            }

        } else {
            tmp = {
                ...this.state.isClicked,
                until: false,
                start: true
            }
        }

        this.setState(
            {
                isClicked: tmp,
                selectedDay: tmp2
            }
        );
    }

    handleDayClick = (day, { selected }) => {
        let tmp = {
            ...this.state.isClicked,
            until: false,
            start: false,
        }
        let tmp2 = {};

        if (this.state.isClicked['until']) {
            tmp2 = {
                ...this.state.selectedDay,
                start: this.state.selectedDay['start'],
                until: day
            }
        }
        if (this.state.isClicked['start']) {
            tmp2 = {
                ...this.state.selectedDay,
                start: day,
                until: this.state.selectedDay['until'],
            }
        }
        this.setState(
            {
                isClicked: tmp,
                selectedDay: tmp2
            }
        );
    }

    getDateForInput = (day) => {
        if (day === null) return null;
        let defDate = new Date(day);
        return defDate = ((defDate.getDate() < 10) ? ('0' + defDate.getDate()) : defDate.getDate()) + ' / ' + (((defDate.getMonth() + 1) < 10) ? ('0' + (defDate.getMonth() + 1)) : (defDate.getMonth() + 1)) + ' / ' + defDate.getFullYear();
    }

    onChangeDateHandler(value, formElementId) {
        if (formElementId === 'until') {
            if (this.props.tmf.until.value !== this.getDateForInput(this.state.selectedDay[formElementId]) && this.state.selectedDay[formElementId] !== null) {
                this.props.onDateChanged(value, formElementId)
            }
        } else {
            if (this.props.tmf.start.value !== this.getDateForInput(this.state.selectedDay[formElementId]) && this.state.selectedDay[formElementId] !== null) {
                this.props.onDateChanged(value, formElementId)
            }
        }
    }

    handleChange = selectedOption => {
        this.setState(
            { selectedOption },
        );
        this.props.onTemplateChanged(selectedOption.value);
    };

    render() {
        const formElementsArray = [];
        const publicShare = this.props.tmf.published.value? <React.Fragment><label className={classes.Reminder}>Publishing URL:</label><div style={{ marginBottom: "5px" }}><a target="_blank" rel="noopener noreferrer" href={"/published/" + this.props.tmf.hash.value}>/published/{this.props.tmf.hash.value}</a></div></React.Fragment> :'';
        for (let key in this.props.tmf) {
            formElementsArray.push({
                id: key,
                config: this.props.tmf[key]
            });
        }

        let tBtn;
        if (!this.props.selit) {
            tBtn = (<Button clicked={this.saveData} disabled={!this.props.formIsValid}>Create</Button>);
        } else {
            tBtn = (
                <div className="align-right">
                    <Button clicked={this.updateData} btnType="update" disabled={!this.props.formIsValid}>Update</Button>
                    <Button clicked={this.deleteData} btnType="delete" disabled={!this.props.formIsValid}>Delete</Button>
                </div>
            );
        }
        let tForm = (
            <form>
                {formElementsArray.map(formElement => {
                    switch (formElement.config.elementConfig.type) {
                        case 'input':
                            return <Input
                                key={formElement.id}
                                isDatePicker={false}
                                elementConfig={formElement.config.elementConfig}
                                title={formElement.config.elementConfig.title}
                                placeholder={formElement.config.elementConfig.placeholder}
                                value={formElement.config.value}
                                invalid={!formElement.config.valid}
                                shouldValidate={formElement.config.validation}
                                touched={formElement.config.touched}
                                changed={(event) => this.props.onInputChanged(event, formElement.id)} />
                        case 'text':
                            const info = isMobile? null:<p className={classes.Info}><FontAwesomeIcon className={classes.InfoIco} icon={faInfoCircle} />You can create resolution goals by using some of our predefined templates. But the most important thing is that you create specific, measurable, attainable, realistic, and time-limited (S.M.A.R.T.) goals.</p>
                            return <React.Fragment key={formElement.id + 10}>
                                <div className={classes.DropForEdit}>
                                    {info}
                                    <Select
                                        styles={customStyles}
                                        value={this.state.selectedOption}
                                        onChange={this.handleChange}
                                        options={options}
                                    />
                                    <SlateEditor
                                        key={this.state.selectedOption ? this.state.selectedOption.value : formElement.id}
                                        className={classes.ToolBar}
                                    />
                                </div>
                            </React.Fragment>;
                        case 'date':
                            return <Input
                                key={formElement.id + '_' + Math.floor(Math.random() * 10000)}
                                isDatePicker={true}
                                elementConfig={formElement.config.elementConfig}
                                title={formElement.config.elementConfig.title}
                                placeholder={formElement.config.elementConfig.placeholder}
                                value={this.state.selectedDay[formElement.id] ? this.getDateForInput(this.state.selectedDay[formElement.id]) : formElement.config.value}
                                invalid={!formElement.config.valid}
                                shouldValidate={formElement.config.validation}
                                touched={formElement.config.touched}
                                clicked={(event) => this.isClicked(formElement.id)}
                                selectedDays={this.state.selectedDay[formElement.id]}
                                onDayClick={this.handleDayClick}
                                isClicked={this.state.isClicked[formElement.id]}
                                changed={this.onChangeDateHandler(this.getDateForInput(this.state.selectedDay[formElement.id]), formElement.id)} />
                        case 'radio':
                            return (
                                <FormControl className={classes.Half} component="fieldset" key={formElement.id}>
                                    <label className={classes.Reminder}>{formElement.config.elementConfig.title}</label>
                                    <RadioGroup aria-label="position" name="position" value={parseInt(formElement.config.value)} onChange={(event) => this.props.onInputChanged(event, formElement.id)} row>
                                        <FormControlLabel
                                            value="7"
                                            control={<BlueRadio checked={parseInt(formElement.config.value) === 7} />}
                                            name="radio-button-demo"
                                            label="Week"
                                            labelPlacement="start"
                                        />
                                        <FormControlLabel
                                            value="30"
                                            control={<BlueRadio checked={parseInt(formElement.config.value) === 30} />}
                                            name="radio-button-demo"
                                            label="Month"
                                            labelPlacement="start"
                                        />
                                        <FormControlLabel
                                            value="182"
                                            control={<BlueRadio checked={parseInt(formElement.config.value) === 182} />}
                                            name="radio-button-demo"
                                            label="6 Months"
                                            labelPlacement="start"
                                        />
                                    </RadioGroup>
                                </FormControl>);
                        case 'checkbox':
                            let controlElem = null;
                            if (formElement.id === 'published') {
                                controlElem = <FormControl className={classes.Half} component="fieldset" key={formElement.id} >
                                    <label className={classes.Reminder}>{formElement.config.elementConfig.title}</label>
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            control={<BlueCheckbox value={parseInt(formElement.config.value)} checked={parseInt(formElement.config.value) === 1} onChange={(event) => this.props.onCheckBoxChanged(event, formElement.id)} />}
                                            label={formElement.config.elementConfig.placeholder}
                                            labelPlacement="start"
                                        />
                                    </FormGroup>
                                </FormControl>;
                            } else {
                                controlElem = <FormControl className={classes.Half} component="fieldset" key={formElement.id} >
                                    <label className={classes.Reminder}>{formElement.config.elementConfig.title}</label>
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            control={<BlueCheckbox value={parseInt(formElement.config.value)} checked={parseInt(formElement.config.value) === 1} onChange={(event) => this.props.onCheckBoxChanged(event, formElement.id)} />}
                                            label={formElement.config.elementConfig.placeholder}
                                            labelPlacement="start"
                                        />
                                    </FormGroup>
                                </FormControl>;
                            }
                            return (controlElem);
                        default:
                            return null;
                    }

                })}
                {publicShare}
                {tBtn}
            </form >
        );
        return (tForm);
    }
};

//REDUX
const mapStateToProps = state => {
    return {
        tmf: state.rdcr.resolutionForm,//input fields configuration
        dtp: state.rdcr.dateParam,//date from url
        selit: state.rdcr.selectedItem,//selected item - for update/delete operations
        formIsValid: state.rdcr.formIsValid,//enable buttons if valid
        api_token: state.ardcr.token
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onTemplateChanged: (value) => dispatch({ type: actionTypes.TEMPLATE_CHANGE, value: value }),
        onDateChanged: (value, formId) => dispatch({ type: actionTypes.DATE_CHANGE, value: value, formId: formId }),
        onInputChanged: (event, formId) => dispatch({ type: actionTypes.INPUT_CHANGE, value: event.target.value, formId: formId }),
        onCheckBoxChanged: (event, formId) => dispatch({ type: actionTypes.CHECKBOX_CHANGE, value: event.target.checked, formId: formId }),
        onFormSave: (obj) => dispatch(actions.saveData(obj)),
        onFormDelete: (id,token) => dispatch(actions.deleteData(id,token)),
        onFormUpdate: (obj) => dispatch(actions.updateData(obj))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);