import React, { Component } from 'react';
import { connect } from 'react-redux';

import Input from '../../components/UI/Form/Input/Input';
import Button from '../../components/UI/Form/Button/Button';
import classes from './Auth.module.css';
import * as actions from '../../store/actions/auth';

class Auth extends Component {
    state = {
        controls: {
            name: {
                elementType: 'input',
                elementConfig: {
                    type: 'name',
                    placeholder: 'Name'
                },
                value: '',
                validation: {
                    required: false,
                },
                valid: true,
                touched: true
            },
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: 'Mail Address'
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false
            },
            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Password'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 8
                },
                valid: false,
                touched: false
            },
            password_confirmation: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Confirm Password'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 8
                },
                valid: false,
                touched: false
            }
        },
        isSignup: false,
        formIsValid: false
    }

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }

    inputChangedHandler = (event, controlName) => {
        let updatedControls = null;
        if (controlName === 'password_confirmation') {
            updatedControls = {
                ...this.state.controls,
                [controlName]: {
                    ...this.state.controls[controlName],
                    value: event.target.value,
                    valid: (this.checkValidity(event.target.value, this.state.controls[controlName].validation) && (this.state.controls.password.value === event.target.value)),
                    touched: true
                }
            };
        } else if (controlName === 'password') {
            updatedControls = {
                ...this.state.controls,
                [controlName]: {
                    ...this.state.controls[controlName],
                    value: event.target.value,
                    valid: this.checkValidity(event.target.value, this.state.controls[controlName].validation),
                    touched: true
                },
                'password_confirmation': {
                    ...this.state.controls.password_confirmation,
                    valid: (!this.state.controls.password_confirmation.touched ||
                        (this.state.controls.password_confirmation.value !== '' && this.state.controls.password_confirmation.value === event.target.value)),
                    touched: this.state.controls.password_confirmation.touched
                }
            };
        } else {
            updatedControls = {
                ...this.state.controls,
                [controlName]: {
                    ...this.state.controls[controlName],
                    value: event.target.value,
                    valid: this.checkValidity(event.target.value, this.state.controls[controlName].validation),
                    touched: true
                }
            };
        }
        let formIsValid = true;
        for (let inputIdentifier in updatedControls) {
            formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
        }
        this.setState({ controls: updatedControls, formIsValid: formIsValid });
    }

    submitHandler = (event) => {
        event.preventDefault();
        this.props.onAuth(this.state.controls.email.value, this.state.controls.password.value, this.state.isSignup, this.state.controls.name ? this.state.controls.name.value : null, this.state.controls.password_confirmation ? this.state.controls.password_confirmation.value : null);
    }

    switchAuthModeHandler = () => {
        let updatedControls = {
            ...this.state.controls,
            'password_confirmation': {
                ...this.state.controls.password_confirmation,
                value: '',
                valid: !this.state.isSignup ? false : true,
                touched: false
            },
            'name': {
                ...this.state.controls.name,
                value: '',
                valid: !this.state.isSignup ? false : true,
                touched: false
            }
        }
        let formIsValid = true;
        for (let inputIdentifier in updatedControls) {
            formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
        }
        this.setState(prevState => {
            return { isSignup: !prevState.isSignup, controls: updatedControls, formIsValid: formIsValid };
        });
    }

    render() {
        const formElementsArray = [];
        for (let key in this.state.controls) {
            formElementsArray.push({
                id: key,
                config: this.state.controls[key]
            });
        }

        const form = formElementsArray.map(formElement => (
            <Input
                className={this.state.isSignup ? classes.Visible : ((formElement.id === 'name' || formElement.id === 'password_confirmation') ? classes.Hidden : classes.Visible)}
                key={formElement.id}
                elementType={formElement.config.elementType}
                value={formElement.config.value}
                placeholder={formElement.config.elementConfig.placeholder}
                type={formElement.config.elementConfig.type}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={(event) => this.inputChangedHandler(event, formElement.id)} />
        ));

        let errorMsgs = [];
        let messagesArr = [];
        if (this.props.err) {
            let errs = this.props.err.data.errors;
            for (let x in errs) {
                errorMsgs.push(errs[x]);
            }
            messagesArr = errorMsgs.map(item => <span style={{ color: "red", paddingBottom: "10px", display: "block" }}>{item}</span>)
        }
        let authLabel = this.state.isSignup? 'Sign Up' : 'Login';
        return (

            <React.Fragment>
                <div className={classes.Auth}>
                <div style={{ textAlign: "left", padding:"0px", margin:"0px", height: "5px", fontSize:"1.2em" }}>{authLabel}</div>
                    <form onSubmit={this.submitHandler}>
                        {form}
                        {messagesArr}
                        <Button clicked={this.submitHandler} btnType="Success" disabled={!this.state.formIsValid}>SUBMIT</Button>
                        <a style={{ marginTop: "5px", display: "block", color:"#333333" }} href="/api/password/reset">Forgot Password?</a>
                    </form>
                </div>
                <Button
                    clicked={this.switchAuthModeHandler}
                    btnType="Danger">{'SWITCH TO ' + ((this.state.isSignup) ? 'SIGNIN' : 'SIGNUP')}</Button>
            </React.Fragment>
        );
    }
}

// REDUX
const mapStateToProps = state => {
    return {
        err: state.ardcr.error
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password, isSignup, name, password_confirmation) => dispatch(actions.auth(email, password, isSignup, name, password_confirmation))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);