import React from 'react';

import classes from './Button.module.css';
import iconPlus from '../../../../assets/icons/add-sharp.svg';
import iconLockedWorld from '../../../../assets/icons/locked-world.svg';
import iconLoggedCircle from '../../../../assets/icons/logged-circle.svg';
/**
 * @description Button component
 * @summary Three types of buttons: Standard button, Two inline buttons and Ico button
 * @param {object} props 
 */
const button = (props) => {
    let disabledBtn = {
        disabled: ((props.disabled) ? 'disabled' : null)
    }
    let btn =
        <div className="align-right">
            <input className={classes.Btn} onClick={props.clicked} type="button" value={props.children} {...disabledBtn} />
        </div>;

    if (props.btnType === 'update') {
        btn = <div className={classes.BtnMulti + " align-right"}>
            <input className={classes.BtnUpd} onClick={props.clicked} type="button" value={props.children} {...disabledBtn} />
        </div>;
    }

    if (props.btnType === 'delete') {
        btn = <div className={classes.BtnMulti + " align-right"}>
            <input className={classes.BtnDel} onClick={props.clicked} type="button" value={props.children} {...disabledBtn} />
        </div>;
    }
    let btnClasses = [classes.BtnIcon];

    if (props.btnType === 'ico') {
        if (props.btnPosition === 'left') {
            btnClasses.push(classes.BtnIconLeft)
            btn = <span className={btnClasses.join(' ')}>
                <img title="ADD NEW RESOLUTION" className={classes.Icon} src={iconPlus} onClick={props.clicked} alt="Add New Resolution" />
            </span>;
        }
        if (props.btnPosition === 'right') {
            btnClasses.push(classes.BtnIconRight)
            btn = <span className={btnClasses.join(' ')}>
                <img key={props.id} title={props.btnSwitch? "LOGOUT" : "LOGIN/REGISTER"} className={classes.Icon} src={props.btnSwitch? iconLoggedCircle : iconLockedWorld} onClick={props.clicked} alt="Authentificate" />
            </span>;
        }
    }
    return (
        btn
    );
};

export default button;