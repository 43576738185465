import React, { Component } from 'react';
import CalendarDay from '../CalendarDay/CalendarDay';
import ReadOnly from '../../SlateEditor/SlateReadOnly';

import 'typeface-roboto';
import svgRun from '../../../assets/icons/run.png';
import svgLaurel from '../../../assets/icons/laurel.svg';
import pngStars from '../../../assets/icons/stars.png';
import pngCup from '../../../assets/icons/cup.png';
import classes from './ResolutionItem.module.css';
import { Link } from 'react-router-dom';

class ResolutionItem extends Component {
    constructor(props) {
        super(props)
        this.state = { matches: window.matchMedia("(max-width: 800px)").matches };
    }

    handler = e => this.setState({ matches: e.matches });

    componentDidMount() {    
        window.matchMedia("(max-width: 800px)").addListener((e) => this.handler(e));
    }

    getDOWFromReminder(reminder) {
        switch (reminder) {
            case 7:
                return 'Weekly';
            case 30:
                return 'Montly';
            case 182:
                return '6 Months';
            default:
                return 'Not set'
        }
    }
    getStatus(status) {
        switch (status) {
            case 1:
                return 'Resolved';
            case 0:
                return 'Not resolved';
            default:
                return 'Not set'
        }
    }

    render() {
        let iconstart = null;
        let iconfinish = null;
        if (this.state.matches && this.props.status === 1) {
            iconstart = <img className={classes.svgFireworks} title="Fireworks" src={pngStars} alt="Resolved" />
        } else if (this.state.matches && this.props.status === 0) {
            iconstart = <img className={classes.svgIco} title="Low Start" src={svgRun} alt="Low Start" />
        } else {
            if (!this.state.matches && this.props.status === 1) {
                iconstart = <React.Fragment><img className={classes.svgIco} title="Low Start" src={svgRun} alt="Low Start" /> <img className={classes.svgFireworks} title="Fireworks" src={pngStars} alt="Resolved" /></React.Fragment>
            } else if (!this.state.matches && this.props.status === 0) {
                iconstart = <img className={classes.svgIco} title="Low Start" src={svgRun} alt="Low Start" />
            }
        }

        if (this.state.matches && this.props.status === 1) {
            iconfinish = <img className={classes.svgCup} title="Golden Cup" src={pngCup} alt="Winner Resolved" />
        } else if (this.state.matches && this.props.status === 0) {
            iconfinish = <img className={classes.svgLaurel} title="Winner" src={svgLaurel} alt="Winner" />
        } else {
            if (!this.state.matches && this.props.status === 1) {
                iconfinish = <React.Fragment><img className={classes.svgLaurel} title="Winner" src={svgLaurel} alt="Winner" /> <img className={classes.svgCup} title="Golden Cup" src={pngCup} alt="Winner Resolved" /></React.Fragment>
            } else if (!this.state.matches && this.props.status === 0) {
                iconfinish = <img className={classes.svgLaurel} title="Winner" src={svgLaurel} alt="Winner" />
            }
        }
        let wrapClasses = (!this.props.edit)? [classes.wrapper, classes.CursorDefault].join(' ') : classes.wrapper;
        let classDetails = (!this.props.hash)? classes.details : classes.hashedDetails;
        let head_txt_url = (this.props.detailshash)? <Link to={"/published/" + this.props.detailshash} >{this.props.title}</Link> : this.props.title;
        console.log(this.props.detailshash);
        return (
            <div className={wrapClasses} id={this.props.id} onClick={() => this.props.clicked(this.props.id)}>
                <article className={classes.main}>
                    <header className={classes.header}>{head_txt_url}</header>

                    <div className={classDetails}>
                        <div className="paper">
                            <div className="pattern">
                                <ReadOnly
                                    key={this.props.id}
                                    body={this.props.body}
                                />
                            </div>
                        </div>
                    </div>

                    <footer className={classes.footer}>
                        <div className={classes.col}>Reminder set: <span className={classes.reminder}>{this.getDOWFromReminder(this.props.reminder)}</span></div>
                        <div className={classes.col}>Resolution is <span className={classes.reminder}>{this.getStatus(this.props.status)}</span></div>
                    </footer>
                </article>
                <aside className={[classes.aside, classes.aside1].join(' ') + ' ' + (this.props.status ? classes.resolved : '')}>
                    <section className={classes.date}>
                        <CalendarDay date={this.props.startdate} />
                        {iconstart}
                    </section>
                </aside>
                <aside className={[classes.aside, classes.aside2].join(' ') + ' ' + (this.props.status ? classes.resolved : '')}>
                    <section className={classes.date}>
                        <CalendarDay date={this.props.untildate} />
                        {iconfinish}
                    </section>
                </aside>

            </div>
        );
    }
}

export default ResolutionItem;
