export const SAVE_FORM = 'SAVE_FORM';
export const INPUT_CHANGE = 'INPUT_CHANGE';
export const DATE_CHANGE = 'DATE_CHANGE';
export const PAGE_CHANGE = 'PAGE_CHANGE';
export const TEXT_CHANGE = 'TEXT_CHANGE';
export const TEMPLATE_CHANGE = 'TEMPLATE_CHANGE';
export const CHECKBOX_CHANGE = 'CHECKBOX_CHANGE';
export const CHANGE_TITLE = 'CHANGE_TITLE';
export const CHANGE_BODY = 'CHANGE_BODY';
export const CHANGE_REMINDER = 'CHANGE_REMINDER';
export const CHANGE_UNTIL = 'CHANGE_UNTIL';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SET_DATE = 'SET_DATE';
export const GET_PUBLISHED = 'GET_PUBLISHED';
export const GET_RESOLUTION = 'GET_RESOLUTION';
export const UPDATE_RESOLUTION = 'UPDATE_RESOLUTION';
export const DELETE_RESOLUTION = 'DELETE_RESOLUTION';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOGOUT_CLEAR = 'AUTH_LOGOUT_CLEAR';
export const OPEN_LOGIN_MODAL = 'OPEN_LOGIN_MODAL';
export const CLOSE_LOGIN_MODAL = 'CLOSE_LOGIN_MODAL';
export const GET_ALL_PUBLISHED_RESOLUTIONS = 'GET_ALL_PUBLISHED_RESOLUTIONS';
export const GET_ALL_EXAMPLES = 'GET_ALL_EXAMPLES';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_INIT_SUCCESS = 'RESET_INIT_SUCCESS';
export const AUTH_RESET_INIT_FAIL = 'AUTH_RESET_INIT_FAIL';
export const RESET_FAIL = 'RESET_FAIL';
export const AUTH_SESSION_FAIL = 'AUTH_SESSION_FAIL';
export const EMPTY_OLD_RESOLUTION = 'EMPTY_OLD_RESOLUTION';