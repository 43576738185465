import React, { Component } from 'react';

import { connect } from 'react-redux';
import Form from '../../components/UI/Form/Form';
import Auth from '../Auth/Auth';
import Backdrop from '../../components/UI/Backdrop/Backdrop';
import classes from './Modal.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
/**
 * @description Popup modal
 * @summary Form container and backdrop container
 * @class Modal
 * @extends {Component}
 */
class Modal extends Component {
    render() {
        let modalContent = null;
        if (this.props.isLogMod) {
            modalContent = <Auth />
        } else {
            modalContent = <Form key={Math.floor(Math.random() * 10000)} closeModal={this.props.hideModal} />
        }
        
        let modalAuthError = null;
        if (this.props.setMM) {
            if (this.props.setMM === 'Unauthenticated.')
            modalAuthError = <p style={{ color:"red" }}>Session/token expired!</p>
        }
        return (
            <React.Fragment>
                <div className={classes.ModalWrap}
                    style={{
                        opacity: (this.props.show === true) ? '1' : '0',
                        visibility: (this.props.show === true) ? 'visible' : 'hidden',
                        zIndex: (this.props.show === true) ? '99999' : '-1'
                    }}>
                    <Backdrop 
                        show={this.props.show} 
                        clicked={this.props.hideModal} 
                    />
                    <div className={classes.Modal}  style={{ overflow: "scroll" }}>
                        <h2>{this.props.upd}:
                        <span className={classes.Close}>
                            <FontAwesomeIcon 
                                className={classes.Pointer} 
                                icon={faTimesCircle} 
                                onClick={this.props.hideModal} 
                            />
                        </span>
                        </h2>
                        {modalAuthError}
                        {modalContent}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

// REDUX
const mapStateToProps = state => {
    return {
        upd: state.rdcr.modalTitle,//handling Modal title between Task creation and Update/Deletion tasks
        isLogMod: state.rdcr.isLoginModal,
        setMM: state.rdcr.setModalMessage
    }
}
export default connect(mapStateToProps)(Modal);