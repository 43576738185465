import axios from 'axios';
import * as actionType from './actionTypes';

/**
 * @description Handling assync REDUX for task creation
 * @param {*} value 
 */
export const storeOnServer = value => {
    return {
        type: actionType.SAVE_FORM,
        data: value
    };
}

/**
 * @description Handling assync REDUX for task creation
 * @param {*} value 
 */
export const errorShow = value => {
    return {
        type: actionType.AUTH_SESSION_FAIL,
        data: value
    };
}

const getConfig = (token) => {
    const config = {
        headers: {
            "Authorization": "Bearer " + token,
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    };
    return config;
}
/**
 * @description Ajax promise - axios REST call for storing data
 * @param {*} sendData 
 */
export const saveData = (sendData) => {
    const config = getConfig(sendData.api_token);
    Object.keys(sendData).forEach((key) => (sendData[key] === '') && delete sendData[key]);
    return dispach => {
        axios.post(process.env.REACT_APP_HOST + '/api/resolutions', sendData, config)
            .then(response => {
                dispach(storeOnServer(response.data));
            })
            .catch(error => {
                console.log(error.response);
                dispach(errorShow(error.response));
                if (error.response.status === 401) {
                    if (localStorage.getItem('token')) localStorage.removeItem('token');
                    if (localStorage.getItem('userId')) localStorage.removeItem('userId');
                    if (localStorage.getItem('email')) localStorage.removeItem('email');
                }
            });
    }
};

/**
 * @description Ajax promise - axios REST call for updating data
 * @param {*} sendData 
 */
export const updateData = (sendData) => {
    const config = getConfig(sendData.api_token);
    Object.keys(sendData).forEach((key) => (sendData[key] === '') && delete sendData[key]);
    return dispach => {
        axios.put(process.env.REACT_APP_HOST + `/api/resolutions/${sendData.id}`, sendData, config)
            .then(response => {
                dispach(updateOnServer(response.data));
            })
            .catch(error => {
                console.log(error.response);
                dispach(errorShow(error.response));
                if (error.response.status === 401) {
                    if (localStorage.getItem('token')) localStorage.removeItem('token');
                    if (localStorage.getItem('userId')) localStorage.removeItem('userId');
                    if (localStorage.getItem('email')) localStorage.removeItem('email');
                }
            });
    }
};
/**
 * @description Handling assync REDUX for reading data
 * @param {*} value 
 */
export const emptyOldResolutions = () => {
    return {
        type: actionType.EMPTY_OLD_RESOLUTION
    };
}

/**
 * @description Handling assync REDUX for reading data
 * @param {*} value 
 */
export const getFromServer = value => {
    return {
        type: actionType.GET_RESOLUTION,
        data: value
    };
}

/**
 * @description Ajax promise - axios REST call for reading data
 * @param {*} sendData 
 */
export const onDataGet = (uiDate, perPage, currentPage, email) => {
    const url = email === null ? process.env.REACT_APP_HOST + `/api/resolutions?per_page=${perPage}&page=${currentPage}` : process.env.REACT_APP_HOST + `/api/resolutions/email?email=${email}&per_page=${perPage}&page=${currentPage}`;
    return dispach => {
        dispach(emptyOldResolutions());
        axios.get(url)
            .then(response => {
                dispach(getFromServer(response.data));
            })
            .catch(error => {
                console.log(error.response);
            });
    }
}

/**
 * @description Handling assync REDUX for reading data
 * @param {*} value 
 */
export const getAllPublishedServer = value => {
    return {
        type: actionType.GET_ALL_PUBLISHED_RESOLUTIONS,
        data: value
    };
}

/**
 * @description Ajax promise - axios REST call for reading data
 * @param {*} sendData 
 */
export const onDataGetAllPublished = (perPage, currentPage) => {
    const url = process.env.REACT_APP_HOST + `/api/resolutions/published?per_page=${perPage}&page=${currentPage}`;
    return dispach => {
        dispach(emptyOldResolutions());
        axios.get(url)
            .then(response => {
                dispach(getAllPublishedServer(response.data));
            })
            .catch(error => {
                console.log(error.response);
            });
    }
}

/**
 * @description Handling assync REDUX for reading data
 * @param {*} value 
 */
export const getExamplesServer = value => {
    return {
        type: actionType.GET_ALL_EXAMPLES,
        data: value
    };
}

/**
 * @description Ajax promise - axios REST call for reading data
 * @param {*} sendData 
 */
export const onDataGetExamples = (perPage, currentPage) => {
    const url = process.env.REACT_APP_HOST + `/api/resolutions/examples?per_page=${perPage}&page=${currentPage}`;
    return dispach => {
        dispach(emptyOldResolutions());
        axios.get(url)
            .then(response => {
                dispach(getExamplesServer(response.data));
            })
            .catch(error => {
                console.log(error.response);
            });
    }
}

/**
 * @description Handling assync REDUX for reading data
 * @param {*} value 
 */
export const getPublishedFromServer = value => {
    return {
        type: actionType.GET_PUBLISHED,
        data: value
    };
}

/**
 * @description Ajax promise - axios REST call for reading data
 * @param {*} sendData 
 */
export const onPublishGet = (hash) => {
    const url = process.env.REACT_APP_HOST + `/api/resolutions/hash?hash=${hash}`;
    return dispach => {
        axios.get(url)
            .then(response => {
                dispach(getPublishedFromServer(response.data));
            })
            .catch(error => {
                console.log(error.response);
            });
    }
}

/**
 * @description Handling assync REDUX for task deletion
 * @param {*} value 
 */
export const deleteFromServer = value => {
    return {
        type: actionType.DELETE_RESOLUTION,
        data: value
    };
}

/**
 * @description Ajax promise - axios REST call for deleting data
 * @param {*} sendData 
 */
export const deleteData = (id, api_token) => {

    return dispach => {
        const config = getConfig(api_token);
        axios.delete(process.env.REACT_APP_HOST + `/api/resolutions/${id}`, config)
            .then(response => {
                dispach(deleteFromServer(response.data));
            })
            .catch(error => {
                console.log(error.response);
                dispach(errorShow(error.response));
                if (error.response.status === 401) {
                    if (localStorage.getItem('token')) localStorage.removeItem('token');
                    if (localStorage.getItem('userId')) localStorage.removeItem('userId');
                    if (localStorage.getItem('email')) localStorage.removeItem('email');
                }
            });
    }
};

/**
 * @description Handling assync REDUX for update data
 * @param {*} value 
 */
export const updateOnServer = value => {
    return {
        type: actionType.UPDATE_RESOLUTION,
        data: value
    };
}