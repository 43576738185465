import React, { Component } from 'react';
import { connect } from 'react-redux';

import Quote from '../../components/Quote/Quote';
import DateCurrent from '../../components/Date/DateCurrent';
import Button from '../../components/UI/Form/Button/Button';

import classes from './Header.module.css';
import * as actionTypes from '../../store/actions/actionTypes';
/**
 * @description Site header
 * @summary Here we set/display date, display Quotes, adding button for task creation...
 * @class Header
 * @extends {Component}
 */
class Header extends Component {

    componentDidMount() {
        const date = this.props.match.params.date ? this.props.match.params.date : (this.props.match.params.d ? (this.props.match.params.d + '-' + this.props.match.params.m + '-' + this.props.match.params.y) : '');
        this.props.onDateSet(date.split('-'));
    }

    shouldComponentUpdate(newProps, newState) {
        if (newProps.datep !== this.props.datep || newProps.tkn !== this.props.tkn) {
            return true;
        }
        return false;
    }

    logoutAndClear = () => {
        this.props.onLogout();
        this.props.onClear();
    }
    render() {
        let isSwitch = (this.props.tkn === null) ? true: false;
        let addResolutionButton = this.props.tkn !== null ? <Button btnType="ico" btnPosition="left" clicked={this.props.onModalOpen} /> : null;
        let LoginLogoutButton = isSwitch ? <Button id="1001" btnType="ico" btnPosition="right" btnSwitch={false} clicked={this.props.onModalLoginOpen} /> : <Button id="1003" btnType="ico" btnPosition="right" btnSwitch={true} clicked={this.logoutAndClear} />;

        return (
            <React.Fragment>
                <header className={[classes.Header, classes[`bgrnd${Math.floor(Math.random() * 24)}`]].join(' ')} >
                    <div className="wrap">
                        {addResolutionButton}
                        {LoginLogoutButton}
                        <Quote />
                    </div>
                    <div className={classes.HeaderInner}>
                        <div className="wrap">
                            <DateCurrent dateStr={this.props.datep ? this.props.datep.replace(/-/g, '/') : ''} />
                        </div>
                    </div>
                </header>
            </React.Fragment>
        );
    }
}

// REDUX
const mapStateToProps = state => {
    return {
        shmdl: state.rdcr.showModal,//open/closes modal state
        datep: state.rdcr.dateParam,//date from url
        tkn: state.ardcr.token
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch({ type: actionTypes.AUTH_LOGOUT }),
        onClear: () => dispatch({ type: actionTypes.AUTH_LOGOUT_CLEAR }),
        onModalLoginOpen: () => dispatch({ type: actionTypes.OPEN_LOGIN_MODAL, modalTitle: "Authentication", selectedItem: null }),
        onModalOpen: () => dispatch({ type: actionTypes.OPEN_MODAL, modalTitle: "Create a resolution", selectedItem: null }),
        onDateSet: (datep) => dispatch({ type: actionTypes.SET_DATE, dateParam: datep })
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);