import React, { Component } from 'react';
import classes from './Privacy.module.css';
/**
 * @description Privacy wrapper component
 * @class Privacy
 * @extends {Component}
 */
class Privacy extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            < main className={classes.Main} >
                <div className={classes.Wrap}>
                    <h2>Privacy Policy</h2>
                    <p>Last updated: Jan 15, 2020</p>

                    <h4>Data collected on the basis of consent</h4>
                    <p>Upon your request and expression of consent, we collect the following data for the purpose of providing services to you. Your data is not used for any other purposes or shared with third parties. It is removed upon your withdrawal of consent or your request to terminate theses services.</p>

                    <h4>User details / Article, Resolution details (content)</h4>
                    <p><strong>Name, email address, content:</strong> this data is collected when you register or when you create a content.
When you log in on the Website, your name and email address will also be saved in cookies/local storage. These are for your convenience so that you do not have to fill in your details again when you create another content. These cookies/local storage items will be saved on your computer until you logout or delete them.</p>
                    <p><strong>Retention period:</strong> the aforementioned data is retained indefinitely so we can recognize and approve any follow - up content operation.</p>
                    <h4>Data collected on the basis of legitimate interest</h4>
                    <p>Based on our <strong>legitimate interests</strong>, we collect the following data for the purpose of running this website. Your data is not used for any other purposes or shared with third parties. It is removed upon your request.</p>

                    <h4>Communications</h4>
                    <p>We may use your Personal Information to contact you with newsletters, marketing or promotional materials and other information...</p>

                    <h4>Log Data</h4>
                    <p>Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages and other statistics.</p>

                    <h4>Statistics</h4>
                    <p>The website uses a minimal build of Google Analytics, a service which transmits website traffic data to Google servers in the United States and allows us to notice trends to improve the user experience on our website. This minimal build processes personal data such as: the unique User ID set by Google Analytics, the date and time, the title of the page being viewed, the URL of the page being viewed, the URL of the page that was viewed prior to the current page, the screen resolution, the time in local timezone, the files that were clicked on and downloaded, the links clicked on to an outside domain, the type of device, and the country, region, and city.
You may opt out of this tracking at any time by activating the “Do Not Track” setting in your browser.</p>

                    <h4>Embedded content from other websites</h4>
                    <p>Articles on the Website may include embedded content (e.g. videos, charts, etc.). Embedded content from other websites behaves in the exact same way as if the visitor had visited the other website.
These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracing your interaction with the embedded content if you have an account and are logged in to that website.</p>

                    <h4>Your rights pertaining your data</h4>
                    <p>You can request that we rectify or erase any personal data we hold about you. Please send your request to <strong>resolutions.page@gmail.com</strong> </p>

                    <ul>
                        <li>The right to withdraw consent</li>
                        <li>The right of access</li>
                        <li>The right to erasure</li>
                        <li>The right to rectification</li>
                        <li>The right to data portability</li>
                        <li>The right to object</li>
                        <li>Notification of data breaches</li>
                        <li>The right to lodge a complaint with a supervisory authority</li>
                    </ul>
                </div>
            </ main>
        );
    }
}

export default Privacy;