import React, { Component } from 'react';
import classes from './Footer.module.css';
import { Link } from 'react-router-dom';
/**
 * @description Footer wrapper component
 * @class Footer
 * @extends {Component}
 */
class Footer extends Component {
    render() {
        return (
            <footer className={classes.Footer}>
                <div className={"wrap " + classes.Static}>
                    <span className={classes.FooterCopy}>
                        <Link to="/privacy">Privacy Policy</Link> | 
                        <Link to="/terms">Terms of Service</Link>
                    </span>
                </div>
                <div className={"wrap " + classes.Static}>
                    <span className={classes.FooterCopy}>&copy; 2020 Resolutions. All rights reserved.</span>
                </div>
            </footer>
        );
    }
}

export default Footer;