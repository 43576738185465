import React, { Component } from 'react';
import ResolutionItem from '../../components/UI/ResolutionItem/ResolutionItem';
import { connect } from 'react-redux';

import quotes from './../../components/Quote/quoteData.json';
import * as actions from '../../store/actions/actions';

import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    ViberShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon,
    ViberIcon
} from 'react-share';

import classes from './Published.module.css';

class Published extends Component {
    /**
     * Getting JSON
     *
     * @memberof Quote
     */
    getQuote = () => {
        return quotes;
    }
    
    componentDidMount() {
        this.props.onPublishGet(this.props.match.params.hash);
    }

    render() {
        let rnd = Math.floor(Math.random() * (100/1.4));
        return < main className={classes.Main} >
            <div className="wrap">
                <ResolutionItem hash={this.props.match.params.hash} startdate={this.props.tmsht.start} reminder={this.props.tmsht.reminder} status={this.props.tmsht.status} untildate={this.props.tmsht.until} key={Math.floor(Math.random() * 10000)} id={1} title={this.props.tmsht.title} body={this.props.tmsht.body} clicked={function () { }} />
                <div className={classes.Center}>
                    <FacebookShareButton
                        url={"https://www.resolutions.page/published/" + this.props.match.params.hash}
                        quote={this.getQuote()['quotes'][rnd]['quote'] + ' - ' + this.getQuote()['quotes'][rnd]['author']}>
                        <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                    <TwitterShareButton
                        url={"https://www.resolutions.page/published/" + this.props.match.params.hash}
                        title={this.getQuote()['quotes'][rnd]['quote'] + ' - ' + this.getQuote()['quotes'][rnd]['author']}>
                        <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                    <LinkedinShareButton
                        url={"https://www.resolutions.page/published/" + this.props.match.params.hash}>
                        <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton>
                    <ViberShareButton
                        url={"https://www.resolutions.page/published/" + this.props.match.params.hash}
                        title={this.getQuote()['quotes'][rnd]['quote'] + ' - ' + this.getQuote()['quotes'][rnd]['author']}>
                        <ViberIcon size={32} round={true} />
                    </ViberShareButton>
                    <WhatsappShareButton
                        url={"https://www.resolutions.page/published/" + this.props.match.params.hash}
                        title={this.getQuote()['quotes'][rnd]['quote'] + ' - ' + this.getQuote()['quotes'][rnd]['author']}>
                        <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>
                </div>
            </div>
        </main>
    }
}

// REDUX
const mapStateToProps = state => {
    return {
        tmsht: state.rdcr.timesheet,//items from specific date
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onPublishGet: (hash) => dispatch(actions.onPublishGet(hash))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Published);