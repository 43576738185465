import React, { Component } from 'react';

import classes from './CalendarDay.module.css';

/**
 * @description Timesheet display item component
 * @class Item
 * @extends {Component}
 */
class CalendarDay extends Component {
    getDay() {
        return this.props.date ? this.props.date.split('/')[0] : '?';
    }
    getMonth() {
        const month = this.props.date ? this.props.date.split('/')[1] : '?';
        switch (month) {
            case '01':
                return 'January';
            case '02':
                return 'February';
            case '03':
                return 'March';
            case '04':
                return 'April';
            case '05':
                return 'May';
            case '06':
                return 'June';
            case '07':
                return 'July';
            case '08':
                return 'August';
            case '09':
                return 'September';
            case '10':
                return 'October';
            case '11':
                return 'November';
            case '12':
                return 'December';
            case '?':
                return '?';
            default:
                break;
        }
    }
    getYear() {
        return this.props.date ? this.props.date.split('/')[2] : '?';
    }
    render() {
        return (
            <p className={classes.calendar} >
                {this.getDay()} <em > {this.getMonth()} </em> <em>{this.getYear()}</em>
            </p>);
    }
}

export default CalendarDay;