import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    token: localStorage.getItem('token')? localStorage.getItem('token') : null,
    userId: localStorage.getItem('userId')? localStorage.getItem('userId') : null,
    error: null,
    email: localStorage.getItem('email')? localStorage.getItem('email') : null,
    loading: false,
    resetMessage: null
};

const authStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
    
    localStorage.setItem('token', action.idToken);
    localStorage.setItem('userId', action.userId);
    localStorage.setItem('email', action.email);

    return updateObject(state, {
        token: action.idToken,
        userId: action.userId,
        email: action.email,
        error: null,
        loading: false
    });
};

const resetSuccess = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: false,
        resetMessage: action.data.message
    });
};

const resetFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        resetMessage: null
    });
}

const resetInitSuccess = (state, action) => {
    console.log(action);
    return updateObject(state, {
        error: null,
        loading: false,
        resetMessage: action.data.message
    });
};

const resetInitFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        resetMessage: null
    });
}

const authLogout = (state, action) => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('email');

    return updateObject(state, {
        token: null,
        userId: null,
        error: null,
        email: null,
        loading: false
    });
};

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
}

const auth = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state);
        case actionTypes.RESET_SUCCESS: return resetSuccess(state, action);
        case actionTypes.RESET_FAIL: return resetFail(state, action);
        case actionTypes.RESET_INIT_SUCCESS: return resetInitSuccess(state, action);
        case actionTypes.AUTH_RESET_INIT_FAIL: return resetInitFail(state, action);
        default:
            return state;
    }
};

export default auth;