import * as actionTypes from '../actions/actionTypes';
import resolutions from '../resolutionTemplates.json';

const initialState = {
    //our items
    timesheet: [],
    //current input text
    title: '',
    //current input body
    body: '',
    //current start date
    start: '',
    //current untill date
    until: '',
    //current input reminder
    reminder: '',
    //current status
    status: 0,
    //current published
    published: 0,
    //current hash
    hash: '',
    //current item selected
    selectedItem: null,
    //total resources
    totalItemsCount: null,
    //per page
    perPage: 10,
    //current page
    currPage: 1,
    //last page
    lastPage: 1,
    //total visible pagination buttons
    paginationButtons: 5,
    //Modal...
    showModal: false,
    isLoginModal: false,
    modelTitle: 'Create a resolution',
    dateParam: null,
    //setMessage on modal for action
    setModalMessage: null,
    // input field configuration
    resolutionForm: {
        title: {
            elementConfig: {
                type: 'input',
                title: 'Title:',
                placeholder: 'Enter title here...'
            },
            value: '',
            validation: {
                required: true,
                minVal: 5
            },
            valid: false,
            touched: false
        },
        body: {
            elementConfig: {
                type: 'text',
                title: 'Body:',
                placeholder: 'Enter content here...'
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        start: {
            elementConfig: {
                type: 'date',
                title: 'Start on:',
                placeholder: 'Select date...'
            },
            value: '',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        until: {
            elementConfig: {
                type: 'date',
                title: 'Complete until:',
                placeholder: 'Select date...'
            },
            value: '',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        reminder: {
            elementConfig: {
                type: 'radio',
                title: 'Remind me every:',
                placeholder: 'Add days here...'
            },
            value: 7,
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        status: {
            elementConfig: {
                type: 'checkbox',
                title: 'Status:',
                placeholder: 'Is resolution resolved?'
            },
            value: 0,
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        published: {
            elementConfig: {
                type: 'checkbox',
                title: 'Availability:',
                placeholder: 'Make it public available?'
            },
            value: 0,
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        hash: {
            elementConfig: {
                type: 'hidden',
                title: 'hash:',
                placeholder: ''
            },
            value: '',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
    },
    formIsValid: false
}

/**
 * Getting JSON
 *
 * @memberof Resolutions
 */
const getResolutions = () => {
    return resolutions;
}

/**
 * @description Checking input field validation
 * @param {string} value 
 * @param {*} rules 
 */
const checkValidity = (value, rules) => {
    let isValid = true;
    if (!rules) {
        return true;
    }

    if (rules.required) {
        isValid = value.trim() !== '' && isValid;
    }

    if (rules.maxVal) {
        isValid = ((value.length <= rules.maxVal) && isValid) ? true : false;
    }

    if (rules.minVal) {
        isValid = ((value.length >= rules.minVal) && isValid) ? true : false;
    }

    return isValid;
}

/**
 * @description Setting Resolution example question advice template
 *
 * @param {*} state
 * @param {*} value
 * @returns
 */
const setBodyTemplate = (state, value) => {
    const templateObj = JSON.stringify(getResolutions()['resolutions'][0]['template_' + value]);

    const setBodyForm = {
        ...state.resolutionForm
    };
    const updatedFormElementBody = {
        ...setBodyForm['body']
    };

    updatedFormElementBody.value = templateObj;
    updatedFormElementBody.touched = true;
    updatedFormElementBody.valid = true;
    setBodyForm['body'] = updatedFormElementBody;
    return setBodyForm;
}
/**
 *@description updating/setting data of form
 *
 * @param {*} state
 * @param {*} itemSelectedObject
 * @returns
 */
const setFields = (state, itemSelectedObject) => {
    const updatedresolutionForm_ = {
        ...state.resolutionForm
    };
    const updatedFormElementBody = {
        ...updatedresolutionForm_['body']
    };
    updatedFormElementBody.value = itemSelectedObject ? itemSelectedObject.body : '';
    updatedFormElementBody.touched = itemSelectedObject ? true : false;
    updatedFormElementBody.valid = itemSelectedObject ? true : false;
    updatedresolutionForm_['body'] = updatedFormElementBody;

    //if (itemSelectedObject && itemSelectedObject.start) {
    const updatedFormElementStart = {
        ...updatedresolutionForm_['start']
    };
    updatedFormElementStart.value = itemSelectedObject ? itemSelectedObject.start : '';
    updatedFormElementStart.touched = true;
    updatedFormElementStart.valid = true;
    updatedresolutionForm_['start'] = updatedFormElementStart;
    //}

    //if (itemSelectedObject && itemSelectedObject.until) {
    const updatedFormElementUntil = {
        ...updatedresolutionForm_['until']
    };
    updatedFormElementUntil.value = itemSelectedObject ? itemSelectedObject.until : '';
    updatedFormElementUntil.touched = true;
    updatedFormElementUntil.valid = true;
    updatedresolutionForm_['until'] = updatedFormElementUntil;
    //}

    const updatedFormElementTitle = {
        ...updatedresolutionForm_['title']
    };
    const updatedFormElementReminder = {
        ...updatedresolutionForm_['reminder']
    };
    const updatedFormElementStatus = {
        ...updatedresolutionForm_['status']
    };

    const updatedFormElementPublished = {
        ...updatedresolutionForm_['published']
    };

    const updatedFormElementHash = {
        ...updatedresolutionForm_['hash']
    };

    updatedFormElementReminder.value = itemSelectedObject ? itemSelectedObject.reminder : 7;
    updatedFormElementReminder.touched = true;
    updatedFormElementReminder.valid = true;
    updatedresolutionForm_['reminder'] = updatedFormElementReminder;

    updatedFormElementStatus.value = itemSelectedObject ? itemSelectedObject.status : 0;
    updatedFormElementStatus.touched = true;
    updatedFormElementStatus.valid = true;
    updatedresolutionForm_['status'] = updatedFormElementStatus;

    updatedFormElementPublished.value = itemSelectedObject ? itemSelectedObject.published : 0;
    updatedFormElementPublished.touched = true;
    updatedFormElementPublished.valid = true;
    updatedresolutionForm_['published'] = updatedFormElementPublished;

    updatedFormElementHash.value = itemSelectedObject ? itemSelectedObject.hash : 0;
    updatedFormElementHash.touched = true;
    updatedFormElementHash.valid = true;
    updatedresolutionForm_['hash'] = updatedFormElementHash;

    updatedFormElementTitle.value = itemSelectedObject ? itemSelectedObject.title : '';
    updatedFormElementTitle.touched = itemSelectedObject ? true : false;
    updatedFormElementTitle.valid = itemSelectedObject ? true : false;
    updatedresolutionForm_['title'] = updatedFormElementTitle;

    return updatedresolutionForm_;
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        //After saving data successfully on the server, we are storing JSON object in our "cache" and add item to content list
        case actionTypes.SAVE_FORM:
            let obj = { ...action.data };
            let list = [
                obj,
                ...state.timesheet
            ];
            return {
                ...state,
                timesheet: list,
                showModal: false,
                isLoginModal: false
            }
        //After saving data successfully on the server, we are storing JSON object in our "cache" and add item to content list
        case actionTypes.AUTH_SESSION_FAIL:
            console.log(action.data.data.message)
            return {
                ...state,
                setModalMessage: action.data.data.message
            }
        //Get specific resolution from server
        case actionTypes.GET_PUBLISHED:
            let published = action.data.data;
            return {
                ...state,
                timesheet: published
            }
        //After data is loaded from the server we are storing it in our "cache" for "later" item list rendering
        case actionTypes.GET_ALL_EXAMPLES:
        case actionTypes.GET_ALL_PUBLISHED_RESOLUTIONS:
            let objctsP = action.data.data;
            let metaP = action.data.meta;
            return {
                ...state,
                timesheet: objctsP,
                lastPage: metaP.last_page,
                currPage: metaP.current_page
            }
        case actionTypes.EMPTY_OLD_RESOLUTION:
            return {
                ...state,
                timesheet: []
            }
        case actionTypes.GET_RESOLUTION:
            let objcts = action.data.data;
            let meta = action.data.meta;
            return {
                ...state,
                timesheet: objcts,
                lastPage: meta.last_page,
                currPage: meta.current_page
                //showModal: false
            }
        //After updating data successfully on the server, we are updating JSON object in our "cache" and update item to content list
        case actionTypes.UPDATE_RESOLUTION:
            const updState = state.timesheet.concat();
            const indexO = state.timesheet.findIndex(ob => ob.id === state.selectedItem);
            updState[indexO] = action.data;
            return {
                ...state,
                timesheet: updState,
                showModal: false,
                isLoginModal: false
            }
        //After deleting successfully data from server we are updating our item list
        case actionTypes.DELETE_RESOLUTION:
            let timesheetprepared_ = state.timesheet.filter(ob => ob.id !== state.selectedItem);
            return {
                ...state,
                timesheet: timesheetprepared_,
                showModal: false,
                isLoginModal: false
            }
        //On input change we are checking validation and updating modal form interface
        case actionTypes.TEXT_CHANGE:
        case actionTypes.DATE_CHANGE:
        case actionTypes.INPUT_CHANGE:
            let value = action.value;
            const updatedresolutionForm = {
                ...state.resolutionForm
            };
            const updatedFormElement = {
                ...updatedresolutionForm[action.formId]
            };

            updatedFormElement.value = value;
            updatedFormElement.valid = checkValidity(updatedFormElement.value, updatedFormElement.validation);
            updatedFormElement.touched = true;
            updatedresolutionForm[action.formId] = updatedFormElement;

            let formIsValid = true;
            for (let inputIdentifier in updatedresolutionForm) {
                formIsValid = updatedresolutionForm[inputIdentifier].valid && formIsValid;
            }
            return {
                ...state,
                resolutionForm: updatedresolutionForm,
                formIsValid: formIsValid
            }
        case actionTypes.CHECKBOX_CHANGE:
            let checkedState = action.value ? 1 : 0;
            const updatedresolutionChkBxForm = {
                ...state.resolutionForm
            };
            const updatedChkFormElement = {
                ...updatedresolutionChkBxForm[action.formId]
            };

            updatedChkFormElement.value = checkedState;
            updatedChkFormElement.valid = true;
            updatedChkFormElement.touched = true;
            updatedresolutionChkBxForm[action.formId] = updatedChkFormElement;

            let chkFormIsValid = true;
            for (let inputIdentifier in updatedresolutionChkBxForm) {
                chkFormIsValid = updatedresolutionChkBxForm[inputIdentifier].valid && chkFormIsValid;
            }
            return {
                ...state,
                resolutionForm: updatedresolutionChkBxForm,
                formIsValid: chkFormIsValid
            }
        case actionTypes.TEMPLATE_CHANGE:
            const updatedBody = setBodyTemplate(state, action.value);
            return {
                ...state,
                resolutionForm: updatedBody,
            }
        //First we check who called modal and than we are setting data for different calls
        //If Edit/Delete we will show selected data inside modal form input elements
        case actionTypes.OPEN_MODAL:
            let itemSelectedObject = null;
            if (action.selectedItem) {
                itemSelectedObject = state.timesheet.find(ob => ob.id === action.selectedItem);
            }

            const updatedresolutionForm_ = setFields(state, itemSelectedObject);

            if (action.selectedItem) {
                return {
                    ...state,
                    modalTitle: action.modalTitle,
                    selectedItem: action.selectedItem,
                    resolutionForm: updatedresolutionForm_,
                    showModal: true,
                    isLoginModal: false,
                    formIsValid: true,
                    setModalMessage: null
                }
            }
            return {
                ...state,
                modalTitle: action.modalTitle,
                selectedItem: null,
                resolutionForm: updatedresolutionForm_,
                showModal: true,
                isLoginModal: false,
                formIsValid: false,
                setModalMessage: null
            }
        case actionTypes.OPEN_LOGIN_MODAL:
            return {
                ...state,
                showModal: true,
                modalTitle: action.modalTitle,
                isLoginModal: true,
                setModalMessage: null
            }
        case actionTypes.CLOSE_LOGIN_MODAL:
            return {
                ...state,
                showModal: false,
                modalTitle: action.modalTitle,
                isLoginModal: false,
                setModalMessage: null
            }
        case actionTypes.AUTH_LOGOUT_CLEAR:
            return {
                ...state,
                showModal: false,
                isLoginModal: false,
                setModalMessage: null
            }
        case actionTypes.CLOSE_MODAL:
            if (state.setModalMessage) window.location.reload();
            const updatedresolutionFormForClose = setFields(state, null);
            /* const res = ReactDOM.unmountComponentAtNode(document.getElementById('SlateEditor'));
            console.log('DELETED',res); */
            return {
                ...state,
                resolutionForm: updatedresolutionFormForClose,
                showModal: false,
                isLoginModal: false,
                setModalMessage: null
            }
        case actionTypes.PAGE_CHANGE:
            return {
                ...state,
                currPage: action.current
            }
        //If date is not set - set current date in dd-mm-yyyy format (add 0 as prefix if less than 10)
        case actionTypes.SET_DATE:
            let defDate = new Date();
            defDate = ((defDate.getDate() < 10) ? '0' + defDate.getDate() : defDate.getDate()) + '-' + (((defDate.getMonth() + 1) < 10) ? '0' + (defDate.getMonth() + 1) : defDate.getMonth() + 1) + '-' + defDate.getFullYear();
            let userDate = action.dateParam;

            if (userDate.length === 3) {
                if (
                    (!isNaN(parseFloat(userDate[0])) && isFinite(userDate[0])
                        && (userDate[0] > 0 && userDate[0] < 32)) &&
                    (!isNaN(parseFloat(userDate[1])) && isFinite(userDate[1])
                        && (userDate[1] > 0 && userDate[1] < 13)) &&
                    (!isNaN(parseFloat(userDate[2])) && isFinite(userDate[2])
                        && (userDate[2] > 1900 && userDate[2] <= 9999)) 
                ) {
                    defDate = ((userDate[0].length === 1) ? '0' + userDate[0] : ((userDate[0].length > 2) ? userDate[0].slice(-2) : userDate[0])) + "-" +
                        ((userDate[1].length === 1) ? '0' + userDate[1] : ((userDate[1].length > 2) ? userDate[1].slice(-2) : userDate[1])) + "-" + userDate[2];
                }
            }
            return {
                ...state,
                dateParam: defDate
            }
        default:
    }
    return state
}

export default reducer;