import React, { Component } from 'react';
import YouTube from 'react-youtube';
import ReactGA from 'react-ga';

import classes from './Home.module.css';
/**
 * @description Home page
 *
 * @class Home Page
 * @extends {Home}
 */
class Home extends Component {
    render() {
        const opts = {
            width: '100%',
            playerVars: { // https://developers.google.com/youtube/player_parameters
                autoplay: 0
            }
        };
        return (
            < main className={classes.Main} >
                <div className={classes.Wrap}>
                    <div className={classes.home}>
                        <h3 className={classes.H3}>Welcome to Resolutions Page.</h3>
                        <p className={classes.paragraph}><strong>This simple application will help you to create your resolutions on the easiest and the fastest way.</strong></p>
                        <p className={classes.paragraph}><span className={classes.highlighted}>Predefined templates</span> will help you in S.M.A.R.T goals creation, and <span className={classes.highlighted}>reminder</span> will take care of your forgetfulness.</p>
                        <p className={classes.paragraph}><span className={classes.highlighted}>Resolution publisher</span> will allowe you to share your efforts and achievements on social networks.</p>
                    </div>
                    <div className={classes.container}>
                        <div className={classes.box1}>
                            <h3>Resolution</h3>
                            <p className={classes.paragraph}>A resolution is a promise to yourself - it is a decision to do something that will improve your life or your situation in some way.</p>
                            <p className={classes.paragraph}>Making a resolution usually refers to some aspect of your life, such as eating healthier, focusing on loved ones more, or managing your finances better. </p>
                            <p className={classes.paragraph}>The resolution is the overarching theme or the implied direction you wish to take with your life.</p>
                            <p>Each resolution is made up of goals; Having that in mind we can define our goals as activities or the specific steps in resolving our resolutions.</p>
                            <p>The very first step in resolving resolution is transforming it into goals.</p>
                            <p>We can use 3 simple steps:</p>
                            <ol>
                                <li><strong>Identify the Desired Results</strong></li>
                                <li><strong>Outline the Actions You Need to Take</strong></li>
                                <li><strong>Create a Timeline for Each Action</strong></li>
                            </ol>
                            <p>Consider, for instance, the difference between these two statements:</p>
                            <ul>
                                <li>New Year’s Resolution: “I’m going to lose weight in the new year.”</li>
                                <li> New Year’s Goal: “I will lose 15 pounds by spring break. I will do so by working out three days per week while also reducing my food intake to 1,500 calories per day.”</li>
                            </ul>
                            <p>The goal is different than the resolution because it’s specific, measurable, attainable, realistic, and time-limited (S.M.A.R.T.).</p>
                            <p className={classes.paragraph}>You can check if your goal can pass this simple SMART test:</p>
                            <ol>
                                <li><strong>Set a very specific goal.</strong> For example: I will add one fruit serving — that's half a cup, chopped — to my current daily diet.</li>
                                <li><strong>Find a way to measure progress.</strong> For example, I will log my efforts each day on my calendar.</li>
                                <li><strong>Make sure it's achievable.</strong> For example, don't set a goal of a daily 5 mile run if you're out of shape. If you can't safely or reasonably accomplish your goal, set a smaller, achievable one.</li>
                                <li><strong>Make sure it's realistic.</strong> It may seem counterintuitive, but choosing the change you most need to make — let's say, quitting smoking or losing weight — isn't as successful as choosing the change you're most confident you'll be able to make. Focus on sure bets: if you picture a 10-point scale of confidence in achieving your goal, where 1 equals no confidence and 10 equals 100% certainty, you should land in the 7-to-10 zone. An additional fruit serving a day is a small, manageable step toward better health.</li>
                                <li><strong>Set time commitments.</strong> Pick a date and time to start. For example, Wednesday at breakfast, I'll add frozen blueberries to cereal. Pick and regular check-in dates: I'll check my log every week and decide if I should make any changes in my routines to succeed. Find an outside deadline that will help keep you motivated. For example, signing up for a charity run or sprint triathlon on a certain date prods you to get a training program under way.</li>
                            </ol>
                            <p><span>S.M.A.R.T test example is taken from:</span>
                                <ReactGA.OutboundLink
                                    eventLabel="Harvard Health Publications"
                                    to="https://www.health.harvard.edu/promotions/harvard-health-publications/simple-changes-big-rewards-a-practical-easy-guide-for-healthy-happy-living?utm_source=delivra&utm_medium=email&utm_campaign=HB20200104-SimpleChanges&utm_id=1823486&dlv-ga-memberid=21952002&dlv-emuid=5681f9fd-c07b-4b7f-8ad8-c547a05cab5f&dlv-mlid=1823486"
                                    target="_blank"
                                >
                                    https://www.health.harvard.edu/ promotions/harvard-health-publications/ simple-changes-big-rewards-a-practical-easy-guide-for-healthy-happy-living
                                </ReactGA.OutboundLink>
                            </p>
                        </div>
                        <div className={classes.box2}>
                            <h3>Why should we set resolutions?</h3>
                            <p>There are a planty of reasons why we should do it.</p>
                            <p className={classes.paragraph}>Ashira Prossack,
                            <ReactGA.OutboundLink
                                    eventLabel="Forbes"
                                    to="https://forbes.com"
                                    target="_blank"
                                > Forbes.com 
                                </ReactGA.OutboundLink> contributor, points that <strong>resolutions give us clarity</strong>:</p>
                            <p className={classes.paragraph}><i>"Creating a career resolution helps you gain clarity on what you want to do in the future. Every part of setting a resolution helps you get clearer on the bigger picture...It can help you realize it’s time to move on to something new, or it can make you realize that you’re really happy doing what you’re doing and you’re content to stay in that position for another year."</i></p>
                            <p className={classes.paragraph}>She also said that It <strong>gives you something to work towards</strong>,</p>
                            <p className={classes.paragraph}><i>"The key is to make your career resolution something that’s actionable so you can truly see your progress. Take the big picture goal and break it down into smaller pieces or micro-goals and set a timeframe in which you want to accomplish them. Each micro-goal will put you one step closer to making your resolution a reality."</i></p>
                            <p className={classes.paragraph}><strong>and keeps you motivated.</strong></p>
                            <p className={classes.paragraph}><i>"When a goal is set, you want to work towards achieving it. Setting a resolution is the same as setting a goal, so it gives you that same sense of motivation to keep working at it until it becomes a reality. The motivation of wanting to achieve your goals can be quite a powerful thing. Without a goal in mind, it’s easy to lose motivation."</i></p>
                            <ReactGA.OutboundLink
                                eventLabel="Forbes Career Resolutions"
                                to="https://www.forbes.com/sites/ashiraprossack1/2020/12/29/career-resolution/"
                                target="_blank"
                            > https://www.forbes.com/sites/ ashiraprossack1/2020/12/29/ career-resolution/
                                </ReactGA.OutboundLink>
                            <br /><br />
                            <p className={classes.paragraph}>Theo Tsaousides, from
                            <ReactGA.OutboundLink
                                    eventLabel="Psychology Today"
                                    to="https://www.psychologytoday.com/"
                                    target="_blank"
                                > Psychology Today
                                </ReactGA.OutboundLink>
                                , using simple language says that <i>"it's what your brain wants, and it's the only way anything ever gets done."</i>
                                <strong>It’s the language of the brain.</strong></p>
                            <p className={classes.paragraph}><i>"One of the most important functions of the brain—and the most recent in terms of our evolution—is <u>executive function</u>, a cluster of cognitive abilities that evolved to enable us to set and achieve goals..."</i></p>
                            <p className={classes.paragraph}>He also points that <strong>Resolution goals mean clarity and that goals give us meaning and make us feel good.</strong></p>
                            <p className={classes.paragraph}><i>"..They literally do. As neuroscientists learn more and more about the emotional circuits of the brain, they are discovering that one of our most basic emotional reactions is happiness through pursuit. Being actively engaged in the pursuit of a goal activates the brain’s pleasure centers, independent of the outcome..."</i></p>
                            <ReactGA.OutboundLink
                                eventLabel="Psychology Today - Why do we need resolutions"
                                to="https://www.psychologytoday.com/us/blog/smashing-the-brainblocks/201512/8-reasons-we-really-do-need-make-resolutions"
                                target="_blank"
                            > https://www.psychologytoday.com/us/ blog/smashing-the-brainblocks/201512/ 8-reasons-we-really-do-need-make-resolutions
                                </ReactGA.OutboundLink><br/><br/>
                            <p>And finally, Mark Victor Hansen, inspirational and motivational speaker, trainer and author, gave us the best answer on this question:</p><ul><li><i>"By recording your dreams and goals on paper, you set in motion the process of becoming the person you most want to be. Put your future in good hands — your own."</i> and </li><li><i>"Don't think it, ink it."</i></li></ul>
                            <p></p>
                        </div>
                        <div className={classes.box3}>
                            <h3>How to achieve any resolution?</h3>
                            <p className={classes.paragraph}><i>"Depending on the study, only between 12% and 39% of resolution-makers succeed. And the success rates plummet as we age."</i></p>
                            <p className={classes.paragraph}>Michael Pietrzak, mindset and habits coach, gave us several examples why do resolutions fail:</p>
                            <ol>
                                <li><strong>Thay are to big</strong></li>
                                <p className={classes.paragraph}><i>"...It’s because humans have a challenge “sweet spot.” Too easy, and we lose interest. Too tough, and we throw our hands in the air.
                            o to the gym five days a week, but we went 10 times in all of 2019, the challenge is too big not to fail..."</i></p>
                                <li><strong>They’re too vague</strong></li>
                                <p className={classes.paragraph}><i>"One of the top resolutions in 2019 was “save money.” That’s a laudable goal that most people can support.But it’s not specific. How much money do you want to save? It’s not measurable. How will you know when you achieve it? We need the promise of a pot full of gold to bother chasing the rainbow..."</i></p>
                                <li><strong>They’re other people’s resolutions</strong></li>
                                <p className={classes.paragraph}><i>"...Or perhaps you want to make more money than your brother, win the baseball tournament or graduate at the top of your class. Those may seem like healthy pursuits, but any time your goal is measured against someone else, you’re motivated not by your own true wants, but by what society wants you to want."</i></p>
                                <li><strong>We expect change NOW.</strong></li>
                                <p className={classes.paragraph}><i>"...I tried meditation once, it didn’t work for me..."</i></p>
                            </ol>
                            <p><span>He also give us detailed step by step guide how to achieve goals:</span>
                                <ReactGA.OutboundLink
                                    eventLabel="Success Complete Guide"
                                    to="https://www.success.com/beyond-resolutions-the-complete-guide-to-achieving-your-new-year-goals/"
                                    target="_blank"
                                > https://www.success.com/beyond-resolutions-the-complete-guide-to-achieving-your-new-year-goals
                                </ReactGA.OutboundLink>
                            </p>
                            <p className={classes.paragraph}>I have also found interesting article on
                            <ReactGA.OutboundLink
                                    eventLabel="Reddit"
                                    to="https://www.reddit.com"
                                    target="_blank"
                                > Reddit.com
                                </ReactGA.OutboundLink> by Joeycrackem; he talks about three methods that you can use to keep on track with your goals.</p>
                            <ol>
                                <li><strong>Remember the bigger picture</strong></li>
                                <p className={classes.paragraph}><i>"Perhaps the biggest reason we fall off our New Year’s Resolutions is that we forget the bigger picture. We forget what made us decide to try to change our lives. We accept short-term pleasure over long-term fulfilment..."</i></p>
                                <li><strong>Understand ‘Parkinson’s Law’</strong></li>
                                <p className={classes.paragraph}><i>"...“Work expands so as to fill the time available for its completion.” ...
Parkinson’s Law should show you that we spend too much time procrastinating on tasks that can be accomplished at a much faster rate. A useful tactic is to shorten the duration you give yourself to accomplish a goal..."</i></p>
                                <li><strong>Focus on habits more than end goals</strong></li>
                                <p className={classes.paragraph}><i>"...Instead of fixating on the end results, we must put a greater focus on the habits that are needed to achieve these results. Your end goal is the summation of thousands of decisions that you have made prior to its attainment. If you want to lose 30lbs before the year ends, every single piece of food you eat is either bringing you closer or farther away from that goal..."</i></p>
                            </ol>
                            <ReactGA.OutboundLink
                                eventLabel="Reddit How to keep them"
                                to="https://www.reddit.com/r/getdisciplined/comments/7ml3er/advice_how_to_actually_keep_your_new_years/"
                                target="_blank"
                            > https://www.reddit.com/r/getdisciplined /comments/7ml3er/advice_how_to_ actually_keep_your_new_years
                            </ReactGA.OutboundLink>
                        </div>
                    </div>
                    <YouTube
                        videoId="OPf0YbXqDm0"
                        opts={opts}
                        onReady={this._onReady}
                    />
                </div>
            </ main>
        );
    }
}
export default Home