import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import reducer from './store/reducers/reducer';
import auth from './store/reducers/auth';

ReactGA.initialize('UA-156355998-1', { standardImplementation: true, siteSpeedSampleRate: 10, userId: null });

const rootReducer = combineReducers({
    rdcr: reducer,
    ardcr: auth
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);
ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// registerServiceWorker();
serviceWorker.unregister();