import React, { Component } from 'react';

import iconCalendar from '../../assets/icons/icon-calendar.svg';
import classes from './DateCurrent.module.css';
/**
 * @description Display date component
 * @class       DateCurrent
 * @extends {Component}
 */
class DateCurrent extends Component {
    render() {
        return (
            <div className={classes.DateWrap}>
                <img className={classes.DateWrapIcon} src={iconCalendar} alt="Calendar" />
                <time className={classes.DateWrapTime}>{this.props.dateStr}</time>
            </div>
        );
    }
}

export default DateCurrent;