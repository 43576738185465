import React, { useCallback, useMemo, useState } from 'react';
import isHotkey from 'is-hotkey';
import { makeStyles } from '@material-ui/core/styles';

import { Editable, withReact, useSlate, Slate } from 'slate-react';
import {Editor, createEditor, Transforms} from 'slate';
import { withHistory } from 'slate-history';
import { connect } from 'react-redux';

import { Button, Icon, Toolbar } from '@material-ui/core';
//import { Button, Icon, Toolbar } from '../components'

import * as actionTypes from '../../store/actions/actionTypes';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

const useStyles = makeStyles({
  root: {
    minHeight: 30,
    //marginTop: -20,
    '@media screen and (min-width:600px)': {
      //marginTop: -40
    }
  },
});

const LIST_TYPES = ['numbered-list', 'bulleted-list']

function isJson(item) {
  item = typeof item !== "string"
    ? JSON.stringify(item)
    : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  if (typeof item === "object" && item !== null) {
    return true;
  }

  return false;
}

const Slateeditor = (props) => {
  let editorContent = null;
  if (isJson(props.tmf.body.value)) {
    editorContent = JSON.parse(props.tmf.body.value);
  } else {
    editorContent = [{
      type: 'paragraph',
      children: [{ text: '' }],
    }];
  }
  const [value, setValue] = useState(
    editorContent
  )

  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const classes = useStyles()

  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  /* const editor = useMemo(
    () => withRichText(withHistory(withReact(createEditor()))),
    []
  ) */

  return (
    <Slate
      editor={editor}
      value={
        value
      }
      onChange={
        value => {
          setValue(value);
          const content = JSON.stringify(value)
          props.onTextChanged(content, 'body');
        }
      }>

      <Toolbar className={classes.root}>
        <MarkButton format="bold" icon="format_bold" />
        <MarkButton format="italic" icon="format_italic" />
        <MarkButton format="underline" icon="format_underlined" />
        <MarkButton format="code" icon="code" />
        <BlockButton format="heading-one" icon="looks_one" />
        <BlockButton format="heading-two" icon="looks_two" />
        <BlockButton format="block-quote" icon="format_quote" />
        <BlockButton format="numbered-list" icon="format_list_numbered" />
        <BlockButton format="bulleted-list" icon="format_list_bulleted" />
      </Toolbar>
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder="Steps to resolve your resolution…"
        spellCheck
        autoFocus
        onKeyDown={event => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
              event.preventDefault()
              const mark = HOTKEYS[hotkey]
              toggleMark(editor, mark)
            }
          }
        }}
      />
    </Slate >
  )
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type),
    split: true,
  })

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  })

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === format,
  })

  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

/* const withRichText = editor => {
  const { exec } = editor

  editor.exec = command => {
    if (command.type === 'format_block') {
      const { format } = command
      const isActive = isBlockActive(editor, format)
      const isList = LIST_TYPES.includes(format)

      for (const f of LIST_TYPES) {
        Editor.unwrapNodes(editor, { match: n => n.type === f, split: true })
      }

      Editor.setNodes(editor, {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
      })

      if (!isActive && isList) {
        Editor.wrapNodes(editor, { type: format, children: [] })
      }
    } else {
      exec(command)
    }
  }

  return editor
} */



const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    default:
      return <p {...attributes}>{children}</p>
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

const BlockButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

const MarkButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

//REDUX
const mapStateToProps = state => {
  return {
    tmf: state.rdcr.resolutionForm,//input fields configuration
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onTextChanged: (value, formId) => dispatch({ type: actionTypes.TEXT_CHANGE, value: value, formId: formId }),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Slateeditor);