import React from 'react';
import classes from './Pagination.module.css';

class Pagination extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = { matches: window.matchMedia("(min-width: 415px)").matches };
    }

    componentDidMount() {
        const handler = e => this.setState({ matches: e.matches });
        window.matchMedia("(min-width: 415px)").addListener(handler);
    }

    render() {
        if (this.props.lastPage === 1) return null;

        let first_prev = null;
        let mobile_first_prev = null;
        let mobile_next_last = null;
        let next_last = null;

        let dots = <li className={"page-item"}>
            <p className={classes.pageLink}>...</p>
        </li>

        let current = <li className={"page-item active"}>
            <p className={classes.pageLink}>{this.props.currentPage}</p>
        </li>
        //show first only if total pages number is greather than predefined number of buttons + margin
        // and current page is greather than number of buttons
        if ((this.props.paginationButtons < this.props.lastPage) &&
            (this.props.currentPage > Math.ceil(this.props.paginationButtons / 2))) {
            first_prev = <React.Fragment>
                <li className={"page-item"}>
                    <div className={classes.pageLink} onClick={() => this.props.setPage(1)}>First</div>
                </li>
                <li className={"page-item"}>
                    <div className={classes.pageLink} onClick={() => this.props.setPage(this.props.currentPage - 1)}>Previous</div>
                </li>
            </React.Fragment>
        }

        mobile_first_prev = <React.Fragment>
            <li className={"page-item"}>
                <div className={classes.pageLink} onClick={() => this.props.setPage(1)}>{"<<"}</div>
            </li>
            <li className={"page-item"}>
                <div className={classes.pageLink} onClick={() => this.props.setPage(this.props.currentPage - 1)}>{"<"}</div>
            </li>
        </React.Fragment>

        //show last only if total pages number is greather than predefined number of buttons + margin
        // and current page is smaller than number of buttons
        if ((this.props.paginationButtons < this.props.lastPage) &&
            (this.props.currentPage + Math.floor(this.props.paginationButtons / 2) < (this.props.lastPage))) {
            next_last = <React.Fragment>
                <li className={"page-item"}>
                    <div className={classes.pageLink} onClick={() => this.props.setPage(this.props.currentPage + 1)}>Next</div>
                </li>
                <li className={"page-item"}>
                    <div className={classes.pageLink} onClick={() => this.props.setPage(this.props.lastPage)}>Last</div>
                </li>
            </React.Fragment>
        }
        mobile_next_last = <React.Fragment>
            <li className={"page-item"}>
                <div className={classes.pageLink} onClick={() => this.props.setPage(this.props.currentPage + 1)}>{">"}</div>
            </li>
            <li className={"page-item"}>
                <div className={classes.pageLink} onClick={() => this.props.setPage(this.props.lastPage)}>{">>"}</div>
            </li>
        </React.Fragment>

        let buttons = []; let maxTotalBtns = (this.props.lastPage < this.props.paginationButtons) ? this.props.lastPage : this.props.paginationButtons;
        let j = 1;
        if (((this.props.currentPage - Math.floor(this.props.paginationButtons / 2)) > 1)) {
            if (this.props.currentPage < this.props.lastPage - Math.floor(this.props.paginationButtons / 2)) {
                j = this.props.currentPage - Math.floor(this.props.paginationButtons / 2);
            } else {
                j = this.props.lastPage - (Math.floor(this.props.paginationButtons / 2) * 2)
            }
        }

        for (let i = j; i < maxTotalBtns + j; i++) {
            buttons.push(<li key={i} className={"page-item " + (parseInt(this.props.currentPage) === i ? classes.active : "")}>
                <div className={classes.pageLink} onClick={() => this.props.setPage(i)}>{i}</div>
            </li>)
        }



        return (
            <ul className="pagination">
                {!this.state.matches && mobile_first_prev}
                {!this.state.matches && current}
                {!this.state.matches && mobile_next_last}

                {this.state.matches && first_prev}
                {this.state.matches && first_prev && dots}
                {this.state.matches && buttons}
                {this.state.matches && next_last && dots}
                {this.state.matches && next_last}
            </ul>
        );
    }
}

export default Pagination;