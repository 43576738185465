import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';

class Navbar extends Component {

    componentDidMount() {
        if (this.props.logged) {
            ReactGA.set({ userId: localStorage.getItem('email') });
        }
        ReactGA.pageview(this.props.active + window.location.search);
    }
    
    /* shouldComponentUpdate(newProps, newState) {
        if (newProps.active !== this.props.active) {
            return true;
        }
        return false;
    } */

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.active !== this.props.active) {
            if (this.props.logged) {
                ReactGA.set({ userId: localStorage.getItem('email') });
            }
            ReactGA.pageview(this.props.active + window.location.search);
        }
    }

    render() {
        const navigation = < main className="main" >
            <div className="wrap">
                <nav className="navbar navbar-expand-lg navbar-expand-md navbar-expand-sm navbar-light bg-light">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className={"nav-link " + ((this.props.active === "/") ? "active" : "")} to="/">{this.props.logged? 'My Resolutions':'Home'}</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={"nav-link " + ((this.props.active === "/published") ? "active" : "")} to="/published">Published</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={"nav-link " + ((this.props.active === "/examples") ? "active" : "")} to="/examples">Examples</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </ main>;
        return (navigation);
    }
}

export default Navbar;
