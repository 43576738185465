import React from 'react';
import DayPicker from 'react-day-picker';

import 'react-day-picker/lib/style.css';
import classes from './Input.module.css';
/**
 * @description Input wrapper component
 * @summary Label and input into one component
 * @param {object} props 
 */

const input = (props) => {
    const inputClasses = [classes.Field];
    if (props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push(classes.Invalid);
    }
    const classWrapperInput = [classes.FieldWrap];
    if (props.isDatePicker) {
        classWrapperInput.push(classes.Half);
    }

    return (
        <div className={classWrapperInput.join(' ') + ' ' + props.className} >
            <label className={classes.Label} htmlFor={props.id}>{props.title}</label>
            <input 
                key={props.id} 
                className={inputClasses.join(' ')} 
                value={props.value} 
                onChange={props.changed} 
                onClick={props.clicked} 
                type={props.type!=='password'? 'text' : 'password'} 
                readOnly={props.isDatePicker}
                id={props.id} 
                placeholder={props.placeholder} 
            />
            {props.isClicked ? <DayPicker key={'dtp_' + Math.floor(Math.random() * 10000)} selectedDays={props.selectedDays} onChange={props.changed} onDayClick={props.onDayClick} /> : null}
        </div>
    );
};

export default input;